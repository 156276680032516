import { Routes, Route, Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import React, { useContext } from 'react';
import { UserContext } from '../shared/userContext.js';
import Contacts from '../Contacts';
import FAQ from '../FAQ';
import Login from '../auth/Login';
import Submission from '../chat/Submission.js';
import Biographies from '../biographies/Biographies';
import Pricing from '../pricing/Pricing';
import Home from '../home/Home';

function Menu() {
    const { name } = useContext(UserContext);
    return (
        <div>
            <Navbar bg="dark" variant="dark" expand="lg" style={{ marginBottom: "2%" }}>
                    <Navbar.Brand style={{ paddingLeft: '2%' }} as={Link} to="/">Aeon Story</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-between" style={{ paddingRight: '15px' }}>
                        <Container>
                        <Nav>
                            <Nav.Link as={Link} to="/">Home</Nav.Link>
                            <Nav.Link as={Link} to="/submit">Submit</Nav.Link>
                            <Nav.Link as={Link} to="/pricing">Pricing</Nav.Link>
                            <Nav.Link as={Link} to="/biographies">Biographies</Nav.Link>
                            <Nav.Link as={Link} to="/faq">FAQ</Nav.Link>
                            <Nav.Link as={Link} to="/contacts">Contacts</Nav.Link>
                        </Nav>
                        </Container>
                        <Container className="justify-content-end" style={{ paddingRight: '20px' }}>
                            <Nav className="justify-content-end">
                                <Nav.Link as={Link} to="/login">{name ? name : "Login"}</Nav.Link>
                            </Nav>
                        </Container>
                    </Navbar.Collapse>

            </Navbar>
            <Routes>
                <Route path="/submit" element={<Submission />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/biographies" element={<Biographies />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<Home />} />
            </Routes>
        </div>
    );
}

export default Menu;