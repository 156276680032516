import { useState }  from 'react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from '@chatscope/chat-ui-kit-react';
import GptParameters from './GptParameters.js'

const FIRST_QUESTION = "Hello, I'm your personal biographer. " +
"Tell me everything you want to share with the world about yourself, your journey, " + 
"or someone you care about and keep the memory intact." +
"\nWhom are we going to write about?\n";

const LOGIN_MESSAGE = `Please, <a href="/login">create an account</a> or <a href="/login">log in</a> to enable the chat feature.`;
const GENERATION_INPUT = "Generate a biography.";
// From guide: https://rollbar.com/blog/how-to-integrate-chatgpt-with-react/
function Chat({updateBiography, isSignedIn}) {
  const [messages, setMessages] = useState([
    {
      message: isSignedIn ? FIRST_QUESTION : FIRST_QUESTION + LOGIN_MESSAGE,
      sentTime: "just now",
      sender: "Biographer",
    },
  ]);

  const [isTyping, setIsTyping] = useState(false);
  
  const handleSendRequest = async (message) => {
    const newMessage = {
      message,
      direction: 'outgoing',
      sender: "user",
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);

    if (!isSignedIn) {
      const loginMessageObject = {
        message: LOGIN_MESSAGE,
        sentTime: "just now",
        sender: "Biographer",
      };
      setMessages((prevMessages) => [...prevMessages, loginMessageObject]);
      return;
    }

    setIsTyping(true);

    try {
      const response = await processMessageToChatGPT([...messages, newMessage]);
      const content = response.choices[0]?.message?.content;
      if (content) {
        const chatGPTResponse = {
          message: content,
          sender: "ChatGPT",
        };
        setMessages((prevMessages) => [...prevMessages, chatGPTResponse]);
        if (message === GENERATION_INPUT) {
            updateBiography(content);
        }
      }
    } catch (error) {
      console.error("Error processing message:", error);
    } finally {
      setIsTyping(false);
    }
  };


  async function processMessageToChatGPT(chatMessages) {
    const apiMessages = chatMessages.map((messageObject) => {
      const role = messageObject.sender === "ChatGPT" ? "assistant" : "user";
      return { role, content: messageObject.message };
    });

    const apiRequestBody = {
      "model": "gpt-3.5-turbo",
      "messages": [
        { role: "system", content: GptParameters.SYSTEM_MESSAGE },
        ...apiMessages,
      ],
    };

    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + GptParameters.API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    });

    return response.json();
  }
  
  const handleGenerateBiography = async () => {
    await handleSendRequest(GENERATION_INPUT);
  }

  return (
    <div>
      <div style={{ position:"relative", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div style={{ position:"relative", height: "84.5vh", }}>
          <MainContainer>
            <ChatContainer>       
              <MessageList 
                scrollBehavior="smooth" 
                typingIndicator={isTyping ? <TypingIndicator content="Biographer is typing" /> : null}
              >
                {messages.map((message, i) => {
                  return <Message key={i} model={message} />
                })}
              </MessageList>
              <MessageInput attachButton={false} placeholder="Send a Message" onSend={handleSendRequest} />        
            </ChatContainer>
          </MainContainer>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem', width: '100%' }}>
          <button onClick={handleGenerateBiography} style={{ width: '40%' }}>Generate Biography</button>
      </div>
    </div>
  )
}

export default Chat;