import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Menu from './shared/Menu.js'
import { UserContext } from './shared/userContext.js';
import GoogleAnalytics from './GoogleAnalytics.js';

function App() {
  const [name, setName] = useState(null);
  const [userId, setUserId] = useState(null);
  
  useEffect(() => {
    const storedAuthData = localStorage.getItem('authData');
    if (storedAuthData) {
      const { name, userId } = JSON.parse(storedAuthData);
      setName(name);
      setUserId(userId);
    }
    
  }, []);

  return (
    <UserContext.Provider value={{ name, setName, userId, setUserId }}>
      <Router>
        <div className="App">
          <GoogleAnalytics />
          <Menu />
        </div>
      </Router>
    </UserContext.Provider>
  );
}

export default App;