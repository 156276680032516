// Repository.js
import { SUPABASE_CLIENT } from '../shared/supabaseClient.js';

class Repository {
  static async insertStory(text, name, userId) {
    try {
      const authorName = name ? name : "Anon";
      const { error } = await SUPABASE_CLIENT
        .from('Stories')
        .insert([{ story: text, hero_name: "", author_name: authorName, author_id: userId }]);
      if (error) {
        console.error('Error saving biography:', error);
      }
    } catch (error) {
      console.error('Error saving biography:', error);
    }
  }
}

export default Repository;