import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Pricing from '../pricing/Pricing';

function Home() {
  return (
    <div>
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <div>
              <img src="/inspire.jpg" alt="Inspire" style={{ width: '100%', height: 'auto' }} />
            </div>
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <div className="jumbotron">
                <h1><Link to="/submit" style={{ textDecoration: 'none' }}>Submit</Link> a story now by simply chatting with an assistant.</h1>
                <p><b>Don't let your family and yourself be forgotten. It was never as simple to tell a story. Just answer a few questions and
                  we promise to summarize your answers in a compelling story. On top of it, we will make it available for future generations. </b></p>
              </div>
            </Row>
            <Row>
              <div className="jumbotron">
                <h3>Curious how we can keep these stories available for 100s of years? Read our <Link to="/faq" style={{ textDecoration: 'none' }}>FAQ</Link>.</h3>
              </div>
            </Row>
            <Row>
              <div className="jumbotron">
                <h3>Read submitted <Link to="/biographies" style={{ textDecoration: 'none' }}>biographies</Link>.</h3>
              </div>
            </Row>
          </Col>
        </Row>
        <Row>
            <Pricing />
        </Row>
      </Container>
    </div>
  );
}

export default Home;