import React from 'react';

function Contacts() {
  return (
    <div>
      <h1>Contact Us By Email</h1>
      <h2>Email: contact@aeonstory.com</h2>
    </div>
  );
}

export default Contacts;