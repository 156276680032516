import React, { useState } from 'react';
import { SUPABASE_CLIENT } from '../shared/supabaseClient.js';
import { Container, Form, Button, Card, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

function CreateUser() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [error, setError] = useState(null);
  const [createSuccess, setCreateSuccess] = useState(false);

  const handleCreateUser = async (data) => {
    const { email, password, username } = data;

    try {
      const { error } = await SUPABASE_CLIENT.auth.signUp({
        email,
        password,
        options: { 
          data: { name: username }
        }
      });

      if (error) {
        throw error;
      }

      setError(null);
      setCreateSuccess(true);
    } catch (error) {
      console.error('Error creating user:', error);
      setError(error.message);
      setCreateSuccess(false);
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center">
      <Card style={{ width: '22rem' }}>
        <Card.Body>
          <Card.Title>Create User</Card.Title>
          {error && <Alert variant="danger">{error}</Alert>}
          {createSuccess && <Alert variant="success">User created successfully! Please, confirm your email to log in.</Alert>}
          <Form onSubmit={handleSubmit(handleCreateUser)}>
            <Form.Group controlId="CreateUserEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control 
                type="email" 
                placeholder="Enter email" 
                name="email"
                autoComplete="email" 
                {...register('email', { required: true })}
              />
              {errors.email && <p>Email is required</p>}
            </Form.Group>

            <Form.Group controlId="CreateUserPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="Password" 
                name="password" 
                autoComplete="new-password"
                {...register('password', { required: true })}
              />
              {errors.password && <p>Password is required</p>}
            </Form.Group>
            <Form.Group controlId="CreateUserName">
              <Form.Label>User Name</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter user name" 
                name="userName"
                autoComplete="username" 
                {...register('username', { required: true })}
              />
            </Form.Group>
            <Button variant="primary" type="submit" style={{ marginTop: '2%' }}>
              Create User
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CreateUser;