import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../App.css";

function Editor({ setText, text }) {
  const handleTextChange = (value) => {
    setText(value);
  };

  useEffect(() => {
    handleTextChange(text);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  return (
    <div style={{ paddingBottom: "2%" }}>
      <ReactQuill
        className="custom-quill"
        placeholder="Biography will automatically appear here once you request the biography in the chat. Or copy and edit it by hand."
        value={text}
        onChange={handleTextChange}
      />
    </div>
  );
}

export default Editor;