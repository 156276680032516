import { useEffect, useState } from 'react';
import { ListGroup, Form, Card, Row, Col, Container } from 'react-bootstrap';
import { SUPABASE_CLIENT } from '../shared/supabaseClient.js';

const STORY_TRUNCATION_LENGTH_LIMIT = 1500;
const PAGINATION_LIMIT = 10;

function removeLinks(text) {
  return text.replace(/<a\b[^>]*>(.*?)<\/a>/gi, '');
}

function Biographies() {
  const [biographies, setBiographies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchTotalPages = async () => {
      let { count, error } = await SUPABASE_CLIENT
        .from('Stories')
        .select('*', { count: 'exact', head: true });

      if (error) {
        console.error('Error: ', error);
      }

      setTotalPages(Math.ceil(count / PAGINATION_LIMIT));
    };

    fetchTotalPages();
  }, []);

  useEffect(() => {
    const fetchBiographies = async () => {
      const offset = (currentPage - 1) * PAGINATION_LIMIT;
      let { data: biographies, error } = await SUPABASE_CLIENT
        .from('Stories')
        .select('*')
        .order('created_at', { ascending: false })
        .range(offset, offset + PAGINATION_LIMIT - 1);
      if (error) {
        console.error('Error: ', error);
      } else {
        setBiographies(biographies.map((bio) => ({ ...bio, showFullStory: false })));
      }
    };
    fetchBiographies();
  }, [currentPage]);

  const toggleStory = (index) => {
    setBiographies((prevBiographies) =>
      prevBiographies.map((bio, i) =>
        i === index ? { ...bio, showFullStory: !bio.showFullStory } : bio
      )
    );
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  return (
    <Container style={{ maxWidth: '1200px' }}>
      <ListGroup>
        {biographies.map((bio, index) => {
          const modifiedStory = removeLinks(bio.story);
          const truncatedStory = modifiedStory.length <= STORY_TRUNCATION_LENGTH_LIMIT
              ? modifiedStory
              : modifiedStory.slice(0, STORY_TRUNCATION_LENGTH_LIMIT);

          return (
            <ListGroup.Item key={index} style={{ marginBottom: '2%' }}>
              <Card>
                <Card.Header as="h6">
                  <Row>
                    <Col>
                    <span style={{ fontSize: '14px' }}><strong>{bio.hero_name}</strong></span>
                    </Col>
                    <Col className="text-right">
                      <span style={{ fontSize: '14px' }}>Submitted by {bio.author_name} on </span>
                      <span style={{ fontSize: '14px' }}>
                        {new Date(bio.created_at).toLocaleString('default', {
                          month: 'long',
                          year: 'numeric',
                        })}
                      </span>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body style={{ maxWidth: '1200px', overflowX: 'hidden' }}>
                  <Form>
                    <Form.Group className="mb-3">
                      <pre style={{ whiteSpace: 'pre-wrap' }}>
                        {bio.showFullStory ? (
                          <span dangerouslySetInnerHTML={{ __html: modifiedStory }} />
                        ) : (
                          <span dangerouslySetInnerHTML={{ __html: truncatedStory }} />
                        )}
                        {modifiedStory.length > STORY_TRUNCATION_LENGTH_LIMIT + 1 && (
                          <span
                            style={{ color: 'blue', cursor: 'pointer' }}
                            onClick={() => toggleStory(index)}
                          >
                            {bio.showFullStory ? ' Show Less' : ' Show More'}
                          </span>
                        )}
                      </pre>
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Card>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
      <div>
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button key={page} onClick={() => goToPage(page)}>
            {page}
          </button>
        ))}
      </div>
      {currentPage < totalPages && (
        <div>
          <button onClick={() => goToPage(currentPage + 1)}>Next Page</button>
        </div>
      )}
    </Container>
  );
}

export default Biographies;