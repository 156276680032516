import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function GoogleAnalytics () {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-HHHBP1WZQZ');
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return null;
};

export default GoogleAnalytics;