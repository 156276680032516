import React, { useState, useContext } from 'react';
import { UserContext } from '../shared/userContext.js';
import { useNavigate } from 'react-router-dom'; 
import { Container, Form, Button, Card, Alert } from 'react-bootstrap';
import { SUPABASE_CLIENT } from '../shared/supabaseClient.js';
import CreateUser from './CreateUser.js'
import { useForm } from 'react-hook-form'; 

const Login = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [error, setError] = useState(null);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const { name, setName, setUserId } = useContext(UserContext);
  const navigate = useNavigate(); 

  const handleLogin = async (data) => {
    const { email, password } = data;

    const { data: loginData, error: loginError } = await SUPABASE_CLIENT.auth.signInWithPassword({
      email,
      password,
    });

    if (loginError) {
      console.error('Error signing in:', loginError.message);
      setName(null);
      setUserId(null);
      setError(loginError.message);
      setLoginSuccess(false);
    } else {
      setError(null);
      setLoginSuccess(true);
      setName(loginData.user.user_metadata.name);
      setUserId(loginData.user.id);
      localStorage.setItem('authData', JSON.stringify({ name: loginData.user.user_metadata.name, userId: loginData.user.id }));
      navigate(-1);
    }
  };

  const handleLogout = async () => {
    setName(null);
    const { error } = await SUPABASE_CLIENT.auth.signOut();
    if (error) {
      console.error('Error signing out:', error);
    }

    localStorage.removeItem('authData');
  };

  return (
    <Container className="d-flex flex-column justify-content-center align-items-center">
      
      <CreateUser />

      <Card style={{ width: '22rem', marginTop: '2%' }}>
        <Card.Body>
          <Card.Title>Sign In</Card.Title>
          
          {name && <Alert variant="info">You're already logged in as: {name}. Do you want to <span onClick={handleLogout} style={{cursor: 'pointer', color: 'blue'}}>log out?</span></Alert>}
          {error && <Alert variant="danger">{error}</Alert>}
          {loginSuccess && <Alert variant="success">Logged in successfully!</Alert>}
          
          <Form onSubmit={handleSubmit(handleLogin)}>
            <Form.Group controlId="SignInEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control 
                type="email" 
                placeholder="Enter email" 
                name="email" 
                autoComplete="email"
                {...register('email', { required: true })} 
              />
              {errors.email && <p>Email is required</p>} 
            </Form.Group>

            <Form.Group controlId="SignInPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="Password" 
                name="password"
                autoComplete="current-password"
                {...register('password', { required: true })} 
              />
              {errors.password && <p>Password is required</p>} 
            </Form.Group>

            <Button variant="primary" type="submit" style={{ marginTop: '2%' }}>
              Login
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Login;