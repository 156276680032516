import React from 'react';
import { Container } from 'react-bootstrap';

function FAQ() {
  return (
    <div>
      <h1>Frequently Asked Questions</h1>
      <Container style={{ textAlign: 'left', marginTop: "2%" }}>
        <h2>How can you guarantee the storage of my stories for 100+ years?</h2>
        <p>
          Once we reach 1,000 free users, we will start charging customers for the long-term storage guarantee. First 1,000 users will get the guarantee for free.
          <li>Having payed customers will allow us invest into the conventional methods of safe data storage, achieving the level of safety and redundancy that is similar to the banking industry.</li>
          <br />
          At 1,000 paid users, we will use a substantial amount of redundancy in the data copies, both digital and analog.
          <li>For digital safety, we will store all of the data both in local storages and a combination of cloud providers, having multiple layers of redundancy at each step.</li>
          <li>For analog, we will store all of the stories from paid customers and the first 1,000 customers in a paper format in multiple international libraries.</li>
          <br />
          At 50,000 paid users we will employ extremely robust steps.
          <li>Adjust the structure of the company to guarantee the long-term survival of the adjacent non-profit foundation.</li>
          <li>Mitigate the risk of data loss from a global catastrophy by putting digital and analog copies of our stories in storages that would be likely to survive a nuclear war.</li>
        </p>
      </Container>
    </div>
  );
}

export default FAQ;