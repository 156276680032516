import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function PricingPlan({ title, price, features, isHighlighted, isAvailable }) {
    const buttonStyle = isHighlighted ? { marginTop: 'auto', border: '1px solid', backgroundColor: 'blue', color: 'white' } : { marginTop: 'auto', border: '1px solid' };
    const priceStyle = isHighlighted ? { backgroundColor: 'yellow' } : {};
    const divStyle = isHighlighted ? { border: '5px solid purple' } : { border: '1px solid gray' };
    const widthStyle = window.innerWidth <= 768 ? 'calc(100% - 10px)' : 'calc(33.33% - 20px)';
    
    return (
      <div style={{ ...divStyle, borderRadius: '5px', padding: '10px', boxShadow: '1px 1px 5px gray', textAlign: 'center', width: widthStyle, backgroundColor: 'white', margin: '5px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div style={{ height: '150px' }}>
          <h3 style={{ fontSize: '30px', fontWeight: 'bold' }}>{title}</h3>
          <p style={{ ...priceStyle, fontSize: '28px', fontWeight: 'bold' }}>{price}</p>
        </div>
        <ul style={{ color: 'black', listStyle: 'none', textAlign: 'left' }}>
          {features.map((feature) => (
              <li key={feature}>
              <svg
                  style={{ color: 'green', marginRight: '5px', verticalAlign: 'bottom' }}
                  fill="none"
                  height="24"
                  stroke="green"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
              >
                  <polyline points="20 6 9 17 4 12" />
              </svg>
              {feature}
              </li>
          ))}
        </ul>
        <Button style={buttonStyle} as={Link} to={isAvailable ? "/submit" : "/contacts"}>
            {isAvailable ? "Get Started" : "Contact Us"}
        </Button>
      </div>
    );
}

export default function Component() {
    const plans = [
        {
          title: 'Write a Biography',
          price: 'Free',
          features: [
            'Write a biography by simply chatting with an AI assistant.',
            'Make the biography available on the website.',
          ],
          isHighlighted: false,
          isAvailable: true,
        },
        {
          title: 'Long-Term Memory',
          price: 'Free for the first 1000 customers',
          features: [
            'Generate a biography by simply chatting with an AI assistant.',
            'Make the biography available on the website.',
            'Make it available for 100+ years.',
            'Priority Email Support',
          ],
          isHighlighted: true,
          isAvailable: true,
        },
        {
          title: 'Get a real book (Coming Soon)',
          price: '$49',
          features: ['Write a book with a life story in a few hours by simply chatting', 'Include photos', 'Deliver it to your home', 'A perfect gift for yourself or your loved ones', 'Share the book online, unless you prefer privacy', 'Hands On Support'],
          isHighlighted: false,
          isAvailable: false,
        },
      ];

  return (
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flexDirection: window.innerWidth <= 768 ? 'column' : 'row'
    }}>
        {plans.map((plan) => (
            <PricingPlan key={plan.title} {...plan} />
        ))}
    </div>
  );
}