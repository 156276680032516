import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../shared/userContext.js';
import { Container, Row, Col } from 'react-bootstrap';
import Chat from './chat.js';
import Editor from './Editor.js'
import Repository from '../shared//Repository.js';
import { SUPABASE_CLIENT } from '../shared/supabaseClient.js';

const LOGIN_MESSAGE = `Please, <a href="/login">create an account</a> or <a href="/login">log in</a> to submit a biography.`;

function Submission() {
  const [text, setText] = useState("");
  const [error, setError] = useState("");
  const { name, userId, setName, setUserId } = useContext(UserContext);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCheckSignedInFinished, setIsCheckSignedInFinished] = useState(false);

  const updateBiography = (biographyText) => {
    setText(biographyText);
  };

  const handleSubmit = async () => {
    if (!isSignedIn) {
      setError(LOGIN_MESSAGE);
      return;
    }
    if (text.length < 100) {
      setError("Biography should be at least 100 characters long");
      return;
    }
    try {
      await Repository.insertStory(text, name, userId);
      setError("");
      setIsSubmitted(true);
    } catch (error) {
      setError("Failed to insert story: " + error.message);
      setIsSubmitted(false);
    }
  };

  const checkIsSignedIn = async () => {
    const { data, error } = (await SUPABASE_CLIENT.auth.getSession());

    if (error || data?.session?.user == null) {
      setIsSignedIn(false);
    } else if (data?.session?.user) {
      setName(data?.session?.user?.user_metadata.name);
      setUserId(data?.session?.user?.id);
      setIsSignedIn(true)
    }
    setIsCheckSignedInFinished(true);
  };

  useEffect(() => {
    checkIsSignedIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container style={{ maxWidth: '95%' }}>
      <Row>
        <Col md={6} style={{ paddingBottom: '2%' }}>
          {isCheckSignedInFinished && <Chat updateBiography={updateBiography} isSignedIn={isSignedIn} />}
        </Col>
        <Col md={6} style={{ paddingBottom: '2%' }}>
          <Editor setText={setText} text={text} />
          <button onClick={handleSubmit} style={{ width: '25%' }}>Submit</button>
          {error && <p style={{ color: 'red' }} dangerouslySetInnerHTML={{ __html: error }}></p>}
          {isSubmitted && !error && <p style={{ color: 'green' }}>Submission successful!</p>}
        </Col>
      </Row>
    </Container>
  );
}

export default Submission;